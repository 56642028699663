import { Component, OnInit } from '@angular/core';
import {
  ModalController,
  NavParams,
  LoadingController,
  AlertController
} from '@ionic/angular';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-modal-networking-agendar',
  templateUrl: './modal-networking-agendar.page.html',
  styleUrls: ['./modal-networking-agendar.page.scss'],
})
export class ModalNetworkingAgendarPage implements OnInit {

  user;
  contato;
  horarios;
  horarioSelecionado;
  mensagem;

  constructor(
    private modalController: ModalController,
    private backEndService: BackendService,
    public loadingController: LoadingController,
    public alertController: AlertController,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    console.table(this.navParams);
    this.user = this.navParams.data.user;
    this.contato = this.navParams.data.contato;
    this.carregarHorarios();
  }


  async carregarHorarios(): Promise<void> {

    this.backEndService.loadHorariosContatoNetworking(this.contato.id).subscribe(async (res: any) => {
      if (res) {
        console.log('resposta do loadHorariosContatoNetworking()', res);
        this.horarios = res;
        if (!res.length) {
          //TODO exibir mensagem quando não houver horario disponível para o usuário

        }

      } else {

      }
    }, async (err: any,) => {
      console.log("erro recebido");
      console.log(err);

    });
  }


  async convidar() {
    console.log('horarioSelecionado', this.horarioSelecionado);
    console.log('mensagem', this.mensagem);

    //envia os dados pro backend
    const loading = await this.loadingController.create({
      message: ''
    });
    await loading.present();

    const fd = new FormData();
    fd.append('horario_networking_id', this.horarioSelecionado);
    fd.append('mensagem', this.mensagem);

    this.backEndService.gravarSolicitacaoHorario(fd).subscribe( (res: any) => {
      console.log('retorno do gravarSolicitacaoHorario');
      console.log(res);
      loading.dismiss();
    }
      , (err: any) => {
        console.log('error');
        console.log(err);
        loading.dismiss();
      }, async () => {
        const onClosedData: string = "Convite enviado com sucesso!";
        await this.modalController.dismiss(onClosedData);
      });

    
  }



  async closeModal() {
    await this.modalController.dismiss();
  }

}
