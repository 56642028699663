import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ToastController, Platform } from '@ionic/angular';
import { catchError } from 'rxjs/operators';
import { apiURL } from '../common/global-constants'

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  constructor(
    public toastController: ToastController,
    private http: HttpClient
  ) { }

  /** EDITAR PERFIL */
  public updatePerfil(formData) {
    return this.http.post(apiURL + "/perfil", formData);
  }
  
  /** CONFIRMAR RESGATE */
  public confirmarResgate(formData) {
    return this.http.post(apiURL + "/resgatar", formData);
  }
  
  /** REGISTRAR DOWNLOAD DO ARQUIVO */
  public registrarDownload(formData) {
    return this.http.post(apiURL + "/registrar-download", formData);
  }

  /** NETWORKING CONTATOS */
  public loadContatosNetworking(): Observable<any> {
    return this.http.get<any[]>(apiURL + "/networking/contatos")
      .pipe(
        catchError(error => {
          return throwError(error.message || 'server error: feed failed');
        })
      )
  }
  /** NETWORKING AGENDA */
  public loadAgendaNetworking(): Observable<any> {
    return this.http.get<any[]>(apiURL + "/networking/agenda")
      .pipe(
        catchError(error => {
          return throwError(error.message || 'server error: feed failed');
        })
      )
  }
  /** NETWORKING CONVERSAS */
  public loadConversasNetworking(): Observable<any> {
    return this.http.get<any[]>(apiURL + "/networking/conversas")
      .pipe(
        catchError(error => {
          return throwError(error.message || 'server error: feed failed');
        })
      )
  }
  public loadConversaNetworking(contatoId): Observable<any> {
    return this.http.get<any[]>(apiURL + "/networking/conversa/" + contatoId)
      .pipe(
        catchError(error => {
          return throwError(error.message || 'server error: feed failed');
        })
      )
  }
  /** NETWORKING HORÁRIOS */
  public loadHorariosNetworking(): Observable<any> {
    return this.http.get<any[]>(apiURL + "/networking/agenda/listar-meus-horarios")
      .pipe(
        catchError(error => {
          return throwError(error.message || 'server error: feed failed');
        })
      )
  }
  public loadHorariosContatoNetworking(userId): Observable<any> {
    return this.http.get<any[]>(apiURL + "/networking/agenda/listar-horarios/" + userId)
      .pipe(
        catchError(error => {
          return throwError(error.message || 'server error: feed failed');
        })
      )
  }
  public gravarSolicitacaoHorario(formData) {
    return this.http.post(apiURL + "/networking/agenda/solicitar", formData);
  }
  public confirmarAgenda(formData) {
    return this.http.post(apiURL + "/networking/agenda/solicitar", formData);
  }

  /** GAME */
  public loadUserGame(): Observable<any> {
    return this.http.get<any[]>(apiURL + "/meu-game")
      .pipe(
        catchError(error => {
          return throwError(error.message || 'server error: feed failed');
        })
      )
  }

  /** PASTAS E CONTEÚDOS */
  public pastas(): Observable<any> {
    return this.http.get<any[]>(apiURL + "/minhas-pastas")
      .pipe(
        catchError(error => {
          return throwError(error.message || 'server error: feed failed');
        })
      )
  }
  public conteudos(): Observable<any> {
    return this.http.get<any[]>(apiURL + "/meus-conteudos")
      .pipe(
        catchError(error => {
          return throwError(error.message || 'server error: feed failed');
        })
      )
  }

  /** FEED E POSTS */
  public feed(): Observable<any> {
    return this.http.get<any[]>(apiURL + "/feed")
      .pipe(
        catchError(error => {
          return throwError(error.message || 'server error: feed failed');
        })
      )
  }

  public userPosts(userId): Observable<any> {
    return this.http.get<any[]>(apiURL + "/posts/user/" + userId)
      .pipe(
        catchError(error => {
          return throwError(error.message || 'server error: get user posts failed');
        })
      )
  }

  public sendPost(formData) {
    return this.http.post(apiURL + "/posting", formData);
  }

  public deletePost(postId) {
    return this.http.delete(apiURL + "/posting/" + postId);
  }

  public likeDislike(postId) {
    return this.http.get(apiURL + "/posts/react/" + postId);
  }

  public bookmarkPost(postId) {
    return this.http.get(apiURL + "/posts/react/" + postId + '/bookmark');
  }


  /** ARQUIVOS */
  loadArquivosUser(): Observable<any> {
    return this.http.get<any[]>(apiURL + "/meus-arquivos",)
      .pipe(
        catchError(error => {
          console.log('catchError loadArquivosUser');
          console.log(error);
          return throwError(error.error);
        })
      )
  }

  /** CONFERENCIAS */
  loadConferenciasUser(): Observable<any> {
    return this.http.get<any[]>(apiURL + "/minhas-conferencias",)
      .pipe(
        catchError(error => {
          console.log('catchError loadConferenciasUser');
          console.log(error);
          return throwError(error.error);
        })
      )
  }

  /** PESQUISAS */
  loadPesquisasUser(): Observable<any> {
    return this.http.get<any[]>(apiURL + "/minhas-pesquisas",)
      .pipe(
        catchError(error => {
          console.log('catchError loadPesquisasUser');
          console.log(error);
          return throwError(error.error);
        })
      )
  }
  
  /** PESQUISAS */
  loadProdutosUser(): Observable<any> {
    return this.http.get<any[]>(apiURL + "/minha-loja",)
      .pipe(
        catchError(error => {
          console.log('catchError loadPesquisasUser');
          console.log(error);
          return throwError(error.error);
        })
      )
  }

  /** AGENDA */
  loadAgendaUser(): Observable<any> {
    return this.http.get<any[]>(apiURL + "/minha-agenda",)
      .pipe(
        catchError(error => {
          console.log('catchError loadAgendaUser');
          console.log(error);
          return throwError(error.error);
        })
      );
  }
  public sendRsvp(formData) {
    return this.http.post(apiURL + "/rsvp", formData);
  }

  /** SISTEMA DE CHAMADOS / TICKETS */
  public loadChamadosUser(): Observable<any> {
    return this.http.get<any[]>(apiURL + "/meus-chamados?detalhes=1",)
      .pipe(
        catchError(error => {
          console.log('catchError listarChamados');
          console.log(error);
          return throwError(error.error);
        })
      );
  }

  public abrirChamado(formData) {
    return this.http.post(apiURL + "/abrir-chamado", formData);
  }

  public solicitarAcessoEmpresa(formData) {
    return this.http.post(apiURL + "/solictar-acesso-empresa", formData);
  }

  public gravarInteracao(id, formData) {
    return this.http.post(apiURL + "/meus-chamados/nova-interacao/" + id, formData);
  }

}
